import { createContext } from "react";
import { IAction } from "./action";
import initialState, { IBibleReaderState } from "./state";

export interface IBibleReaderContext {
  state: IBibleReaderState;
  dispatch: (action: IAction) => void;
}

export const BibleReaderContext = createContext<IBibleReaderContext>({ state: initialState, dispatch: (action: IAction) => {} });
