class GzipTxtHttpService {
  async fetch(url: string) {
    const readableStream = await fetch(url).then(
      (response) => response.body
    );
    const decompressedReadableStream = readableStream!.pipeThrough(
      // @ts-ignore
      new DecompressionStream('gzip')
    );

    return await new Response(decompressedReadableStream, { headers: { "Content-Type": "text/html" } }).text()
  }
}

export default new GzipTxtHttpService();
