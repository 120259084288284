import './main-dashboard.css';
import { Button, Divider, Layout } from "antd";
import { Link } from "react-router-dom";

function MainDashBoard() {
  return (
    <Layout className='dashboard'>
      <div className='dashboard-content'>
        <div>
          <Divider orientation="left">Dashboard</Divider>
          <div className='dashboard-items'>
            <Link to="/read/bible/kjv/gn/1">
              <Button type="primary" ghost>
                Czytaj Biblię
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MainDashBoard;
