import './BibleDropdownMenu.css';
import { BookOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Button } from "antd";
import { Link } from 'react-router-dom';

function BibleDropdownMenu() {
  const items = [
    {
      key: '1',
      label: (
        <Link to={'/read/bible/select-translation'}>
          <div className='bible-menu-item'>
            <BookOutlined />
            <span>Tłumaczenie</span>
          </div>
        </Link>
      )
    },
  ];
  
  return (
    <Dropdown menu={{ items }} placement="topRight" arrow={{ pointAtCenter: true }}>
      <Button className="bible-menu-button" shape="default" icon={<MoreOutlined />} size="large" />
    </Dropdown>
  )
}

export default BibleDropdownMenu;
