import './FontSizeButton.css';
import { FontSizeOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

export enum EFontSizeChange {
  Increase,
  Decrease,
}

export interface FontSizeButtonApi {
  increaseDisabled: boolean;
  decreaseDisabled: boolean;
  onRequestFontSizeChange: (eventType: EFontSizeChange) => void;
}

function FontSizeButton({ increaseDisabled, decreaseDisabled, onRequestFontSizeChange }: FontSizeButtonApi) {
  return (
    <div className="font-size-button">
      <Button disabled={increaseDisabled} onClick={() => onRequestFontSizeChange(EFontSizeChange.Increase)} type='text' icon={<PlusCircleOutlined />} size="large"></Button>
      <FontSizeOutlined style={{ fontSize: '24px' }} />
      <Button disabled={decreaseDisabled} onClick={() => onRequestFontSizeChange(EFontSizeChange.Decrease)} type='text' icon={<MinusCircleOutlined />} size="large"></Button>
    </div>
  );
}

export default FontSizeButton;
