import './BibleContent.css';
import BibleChapter from "../BibleChapter/BibleChapter";
import { useContext } from 'react';
import { BibleReaderContext } from '../../store/context';

function BibleContent() {
  const bibleReaderContext = useContext(BibleReaderContext);
  
  return (
    <div className="bible-reader-scrollable-area">
      <BibleChapter
        verses={bibleReaderContext.state.current.verses}
        fontSize={bibleReaderContext.state.settings.fontSize}
      />
    </div>
  )
}

export default BibleContent;
