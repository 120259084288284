import gzipTxtHttpService from "./gzip-txt.http-service";
import config from "../config/http-api.config";


class BibleContentHttpService {
  async getChapterContent(translation: string, book: string, chapter: string) {
    return gzipTxtHttpService.fetch(`${config.bibleBasePathApi}/bible_read/${translation}/${book}/${chapter}.txt.gz`);
  }
  async getChapterLengthMetadata(translation: string) {
    return gzipTxtHttpService.fetch(`${config.bibleBasePathApi}/bible_read/${translation}/metadata_code_to_chapter_length.txt.gz`);
  }
  async getCodeToNameMetadata(translation: string) {
    return gzipTxtHttpService.fetch(`${config.bibleBasePathApi}/bible_read/${translation}/metadata_code_to_name.txt.gz`);
  }
}

export default new BibleContentHttpService();
