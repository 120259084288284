import { Button } from "antd";
import { useContext } from "react";
import { generatePath, Link } from "react-router-dom";
import { BibleReaderContext } from "../../store/context";
import { ICurrentRead } from "../../store/state";

export function BibleSelectChapter() {
  const bibleReaderContext = useContext(BibleReaderContext);

  const chapters = new Array(bibleReaderContext.state.current.book!.numberOfChapters)
    .fill(1)
    .map((value, index) => value + index);

  return (
    <div className="bible-select-chapter">
      <h3>Biblia</h3>
      <div className="chapters">
        {
          chapters.map((chapterNo) => (
            <Link key={`${chapterNo}`} to={buildChapterUrlFromCurrentState(bibleReaderContext.state.current, chapterNo)}>
              <Button color="default" variant="filled">
                {chapterNo}
              </Button>
            </Link>
          ))
        }
      </div>
    </div>
  )
}

export default BibleSelectChapter;

function buildChapterUrlFromCurrentState(state: ICurrentRead, chapterNo: number): string {
  return generatePath(
    '/read/bible/:translation/:book/:chapter',
    {
      translation: state.translation!.code,
      book: state.book!.code,
      chapter: `${chapterNo}`
    })
}
