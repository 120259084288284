import { IAction } from "./action";
import { FontSize, IBibleReaderState, ICurrentRead } from "./state";


export function reducer(state: IBibleReaderState, action: IAction): IBibleReaderState {
  console.log(
    'DEBUG: reduxer: ',
    'type: ', action.type,
    'payload: ', action.payload,
  );
  let payload: any;
  switch (action.type) {
    case 'update_reading_state':
      payload = action.payload! as ICurrentRead;
      return {
        ...state,
        current: {
          ...payload,
        },
      };
    case 'set_font_size':
      payload = action.payload! as FontSize;
      return {
        ...state,
        settings: {
          ...state.settings,
          fontSize: payload,
        }
      };
    default:
      return state;
  }
}
