import { Button } from 'antd';
import './BibleSelectBook.css';
import { generatePath, Link, Navigate, Params, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { BibleReaderContext } from '../../store/context';
import { IBook } from '../../model/book';

function BibleSelectBook() {
  const bibleReaderContext = useContext(BibleReaderContext);
  const params = useParams();

  if (bibleReaderContext.state.current.isEmpty) {
    return (<Navigate to={buildFallBackUrl(params)} replace />)
  }

  const translation = bibleReaderContext.state.current.translation!.code;
  const bibleBooks: Array<IBook> = bibleReaderContext.state.current.books!;

  return (
    <div className="bible-select-book">
      <h3>Biblia</h3>
      <div className="books">
        {
          bibleBooks.map((bookInfo) => (
            <Link key={bookInfo.code} to={buildUrlLinkForBook(translation, bookInfo.code)}>
              <Button color="default" variant="filled">
                {bookInfo.name}
              </Button>
            </Link>
          ))
        }
      </div>
    </div>
  );
}

export default BibleSelectBook;

function buildUrlLinkForBook(translation: string, code: string): string {
  return `/read/bible/${translation}/${code}/1`;
}

function buildFallBackUrl(params: Params<string>): string {
  return generatePath(
    '/read/bible/:translation/:book/:chapter',
    {
      translation: params.translation!,
      book: 'gn',
      chapter: '1',
    }
  );
}
