import { BibleMetadata } from "../model/bible-metadata.model";
import bibleContentHttpService from "./bible-content.http-service";

class BibleContentService {
  async getChapterContent(translation: string, book: string, chapter: string): Promise<string[]> {
    const rawContent: string = await bibleContentHttpService.getChapterContent(translation, book, chapter);
    return rawContent.split('\n');
  }
  async getMetadata(translation: string): Promise<BibleMetadata> {
    const rowDelimeter = '   ';
    let rawContent = await bibleContentHttpService.getChapterLengthMetadata(translation);
    let parsed: Array<[string, any]> = rawContent
      .split('\n')
      .map(row => row.split(rowDelimeter).map(v => v.trim()))
      .map(chunk => [chunk[0], parseInt(chunk[1], 10)]);

    const metadata: Partial<BibleMetadata> = {
      chapterLengthMap: new Map(parsed),
      codeNameList: []
    };

    rawContent = await bibleContentHttpService.getCodeToNameMetadata(translation);
    parsed = rawContent
      .split('\n')
      .map(row => row.split(rowDelimeter).map(v => v.trim()))
      .map(chunk => [chunk[0], chunk[1]]);

    // @ts-ignore
    metadata.codeNameMapping = new Map(parsed);
    // @ts-ignore
    metadata.codeNameList= parsed

    return metadata as BibleMetadata;
  }
}

export default new BibleContentService();
