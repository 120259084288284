import { BibleMetadata } from './../../../model/bible-metadata.model';
import { IBook } from "../model/book";
import { ITranslation } from "../model/translation";
import { EMPTY_CURRENT_READ_STATE, ICurrentRead } from "../store/state";
import { IChapter } from '../model/chapter';

export interface IBibleReadUrlParams {
  translation: string;
  book: string;
  chapter: string;
}

export function buildCurrentReadState(
  params: IBibleReadUrlParams,
  verses: string[],

  // dependencies
  bibleMetadata: BibleMetadata,
): ICurrentRead | never {
  const didNotProvideUrlParams = !params.translation;
  if (didNotProvideUrlParams) {
    return { ...EMPTY_CURRENT_READ_STATE };
  }

  verify(params, bibleMetadata);

  return {
    id: buildStateId(params),
    translation: buildTranslationInfo(params.translation),
    book: buildBookInfo(params.book, bibleMetadata),
    books: buildBookInfoList(bibleMetadata),
    chapter: buildChapterInfo(parseInt(params.chapter, 10)),
    verses: [...verses],
    isEmpty: false,
  };
}

function buildStateId(params: IBibleReadUrlParams) {
  return `${params.translation}_${params.book}_${params.chapter}`;
}

function verify(params: IBibleReadUrlParams, bibleMetadata: BibleMetadata,): void | never {
  const chapterIsNotANumber = isNaN(params.chapter as unknown as number);
  if (chapterIsNotANumber) {
    throw Error('StateBuilder validation error');
  }

  const bookDoesNotExistInThisTranslation = !bibleMetadata.codeNameMapping.has(params.book);
  if (bookDoesNotExistInThisTranslation) {
    throw Error('StateBuilder validation error');
  }

  const chapterLengthForBook = bibleMetadata.chapterLengthMap.get(params.book)!;
  const requestedChapter = parseInt(params.chapter, 10);
  const chapterIsOutOfRange = !((requestedChapter > 0) && (requestedChapter <= chapterLengthForBook));
  if (chapterIsOutOfRange) {
    throw Error('StateBuilder validation error');
  }
}

function buildChapterInfo(no: number): IChapter {
  return {
    no,
  };
}

function buildBookInfoList(bibleMetadata: BibleMetadata): Array<IBook> {
  return bibleMetadata
          .codeNameList
          .map(([code]) =>
            buildBookInfo(code, bibleMetadata));
}

function buildBookInfo(code: string, bibleMetadata: BibleMetadata,): IBook {
  return {
    code,
    name: bibleMetadata.codeNameMapping.get(code)!,
    numberOfChapters: bibleMetadata.chapterLengthMap.get(code)!,
  };
}

function buildTranslationInfo(code: string): ITranslation {
  return {
    code,
    name: 'King James Version',
    numberOfBooks: 66,
    type: 'bible',
  };
}