import './BibleReaderSettings.css';
import '../../shared-styles/font-size.css';

import { Button, Collapse, Divider, Switch } from "antd";
import FontSizeButton, { EFontSizeChange } from './components/FontSizeButton/FontSizeButton';
import { useContext, useState } from 'react';
import { BibleReaderContext } from '../../store/context';
import { FontSize } from '../../store/state';
import { NotificationOutlined } from '@ant-design/icons';

function BibleReaderSetting() {
  const bibleReaderContext = useContext(BibleReaderContext);

  const containerClassName = [
    'font-size--example-box',
    `${bibleReaderContext.state.settings.fontSize}-font`
  ].join(' ');

  const availableFontSizes = bibleReaderContext.state.settings.availableFontSizes;
  const fontSize = bibleReaderContext.state.settings.fontSize;
  const fontInfo = isMinMaxFontSize(
    bibleReaderContext.state.settings.fontSize,
    bibleReaderContext.state.settings.availableFontSizes,
  )

  // Notification.requestPermission()

  let [notificationPermissionState, setNotificationPermissionState] = useState(Notification.permission);
  const hasPermissionForNotifications = notificationPermissionState === 'granted';
  const notificationIconColor = hasPermissionForNotifications ? 'white' : 'red';
  
  return (
    <div className="settings">
      <Collapse
        items={[
          {
            key: '1',
            label: 'Powiadomienia',
            children: (
              <div>
                {/* <Divider orientation="left">Powiadomienia</Divider> */}
                <div className="settings-content">
                  <div className='settings--permissions'>
                    <NotificationOutlined style={{ color: notificationIconColor }}/>
                    <span style={{ color: notificationIconColor }}>{ hasPermissionForNotifications ? 'Notyfikacje włączone' : 'Notyfikacje wyłączone' }</span>
                    {
                      !hasPermissionForNotifications ? <Button type='primary' onClick={onRequestNotificationPermission}>Włącz Powiadomienia</Button> : null
                    }
                  </div>
                </div>
              </div>
            ),
          },
          {
            key: '2',
            label: 'Czytelność',
            children: (
              <div>
                {/* <Divider orientation="left">Wielkość Czcionki</Divider> */}
                <div className="settings-content">
                  <div className="settings--change-font-size">
                    <FontSizeButton
                      increaseDisabled={fontInfo.max}
                      decreaseDisabled={fontInfo.min}
                      onRequestFontSizeChange={onRequestFontSizeChange} />
                    <span>&nbsp;</span>
                    <span className={containerClassName}>
                      To jest przykładowy text.
                    </span>
                  </div>
                </div>
              </div>
            ),
          },
          {
            key: '3',
            label: 'Tryb bez Internetu',
            children: (
              <div>
                <Divider orientation="left">Co ma działać bez Internetu</Divider>
                <div className="settings-content">
                  
                </div>
              </div>
            ),
          },
        ]}
        defaultActiveKey={['1', '2']}
      />
    </div>
  )

  async function onRequestNotificationPermission() {
    setNotificationPermissionState(
      await Notification.requestPermission()
    );

    let registrations = await navigator.serviceWorker.getRegistrations();
    console.log('SW registrations', registrations);
    registrations = registrations.filter(reg => !!reg);
    if (registrations.length === 0) {
      return;
    }
    console.log('SW sending notification');
    // WARNING
    // FIXME: this only works for 'granted' permission - servise worker must hundle multiple changes
    // break listening immidietly or q
    registrations[0].active!.postMessage({ type: 'NOTIFICATION_PERMISSION_UPDATE' });
    console.log('SW notification sent');
  }

  function onRequestFontSizeChange(eventType: EFontSizeChange) {
    const currentIndex = availableFontSizes.indexOf(fontSize);

    const nextIndex = eventType === EFontSizeChange.Increase ? currentIndex + 1 : currentIndex - 1;

    // @ts-ignore
    bibleReaderContext.dispatch({ type: 'set_font_size', payload: availableFontSizes[nextIndex] });
  }
}

export default BibleReaderSetting;

function isMinMaxFontSize(currentFont: FontSize, available: FontSize[]): { min: boolean; max: boolean; } {
  const currentIndex = available.indexOf(currentFont);
  return {
    min: currentIndex === 0,
    max: currentIndex === (available.length - 1),
  };
}
