import { Layout } from "antd";
import BibleTopBar from "./components/BibleTopBar/BibleTopBar";
import { Content, Footer } from "antd/es/layout/layout";
import BibleNavigation, { BibleNavigationType } from "./components/BibleNavigation/BibleNavigation";
import { generatePath, Outlet, Params, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useReducer } from "react";
import { reducer } from "./store/reducer";
import { BibleReaderContext } from "./store/context";
import initialState from "./store/state";
import bibleContentService from "../../services/bible-content.service";
import { buildCurrentReadState, IBibleReadUrlParams } from "./services/state-builder";
import { buildUrlForNextChapter, buildUrlForPrevChapter } from "./handlers/chapter-navigation.handler";
import { buildUrlForPrevBook, buildUrlForNextBook } from "./handlers/book-navigation.handler";
import BibleDropdownMenu from "./components/BibleDropdownMenu/BibleDropdownMenu";

function BibleReader() {

  const navigate = useNavigate();
  const params = useParams();

  // @ts-ignore
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
  );

  useEffect(() => {
    (async () => {
      if (!params.translation || !params.book || !params.chapter) {
        return;
      }

      const verses = await bibleContentService.getChapterContent(params.translation!, params.book!, params.chapter!);
      const metadata = await bibleContentService.getMetadata(params.translation!);

      // @ts-ignore  
      dispatch({ type: 'update_reading_state', payload: buildCurrentReadState(params as unknown as IBibleReadUrlParams, verses, metadata) });
    })();
  }, [state.current.id, params.translation, params.book, params.chapter]);

  // if (!bibleReadState) {
  //   console.log('navigate', '/read/bible/kjv/gn/1');
  //   return (<Navigate to={'/read/bible/kjv/gn/1'} />);
  // }

  if (state.current.isEmpty) {
    return (<></>);
  }


  return (
    <BibleReaderContext.Provider value={{ state, dispatch }}>
      <Layout className='main'>
        <BibleTopBar bookName={state.current.book!.name} chapter={state.current.chapter!.no} />
        <Layout>
          <Content className='max-width-for-reading-wrapper'>
            <div className="reading-content max-width-for-reading">
              <div className="reading-scrolable-area-wrapper">
                <Outlet />
              </div>
            </div>
          </Content>
        </Layout>
        <Footer className='footer max-width-for-reading-wrapper'>
          <div className="footer-content max-width-for-reading">
            <BibleNavigation onNavigationRequest={onNavigationRequest} />
            <BibleDropdownMenu />
          </div>
        </Footer>
      </Layout>
    </BibleReaderContext.Provider>
  )

  function onNavigationRequest(type: BibleNavigationType) {
    let path: string = '';
    if (BibleNavigationType.Right === type) {
      path = buildUrlForNextChapter(state)
    }
    if (BibleNavigationType.Left === type) {
      path = buildUrlForPrevChapter(state)
    }
    if (BibleNavigationType.Down === type) {
      path = buildUrlForPrevBook(state)
    }
    if (BibleNavigationType.Up === type) {
      path = buildUrlForNextBook(state)
    }

    if (!path) {
      return;
    }

    navigate(path);
  }  
}

export default BibleReader;

function routePatchesBibleReadingPage(pathname: string, params: Params<string>): boolean {
  console.log(pathname)
  // @ts-ignore
  return pathname === generatePath('/read/bible/:translation/:book/:chapter', params);
}
