import { createHashRouter, Navigate, RouterProvider } from "react-router-dom"
import './App.css';
import { ConfigProvider, theme } from 'antd';
import MainDashBoard from "./modules/main-dashboard/main-dashboard";
import BibleReader from "./modules/bible-reader/bible-reader";
import BibleContent from "./modules/bible-reader/components/BibleContent/BibleContent";
import BibleSelectBook from "./modules/bible-reader/components/BibleSelectBook/BibleSelectBook";
import bibleContentService from "./services/bible-content.service";
import { buildCurrentReadState, IBibleReadUrlParams } from "./modules/bible-reader/services/state-builder";
import BibleSelectChapter from "./modules/bible-reader/components/BibleSelectChapter/BibleSelectChapter";
import BibleReaderSetting from "./modules/bible-reader/components/BibleReaderSettings/BibleReaderSettings";
import BibleSelectTranslation from "./modules/bible-reader/components/BibleSelectTranslation/BibleSelectTranslation";

export interface BibleRouterParams {
  translation: string;
  book: string;
  chapter: string;
}

const routes = createHashRouter([
  {
    path: '/',
    element: (<MainDashBoard />),
  },
  {
    path: '/read/bible',
    element: (<BibleReader />),
    errorElement: (<Navigate to="/" replace />),
    children: [
      {
        path: '/read/bible/settings',
        element: (<BibleReaderSetting />),
        errorElement: (<Navigate to="/" replace />),
      }, 
      {
        path: '/read/bible/select-translation',
        element: (<BibleSelectTranslation />),
        errorElement: (<Navigate to="/" replace />),
      }, 
      {
        path: '/read/bible/select-book/from/:translation',
        element: (<BibleSelectBook />),
        errorElement: (<Navigate to="/" replace />),
      }, 
      {
        path: '/read/bible/select-chapter/from/:translation/:book',
        element: (<BibleSelectChapter />),
        errorElement: (<Navigate to="/" replace />),
      }, 
      {
        path: '/read/bible/:translation/:book/:chapter',
        element: (<BibleContent />),
        errorElement: (<Navigate to="/" replace />),
        loader: async ({ params }) => {
          console.log('-------------------------------------');
          const verses = await bibleContentService.getChapterContent(params.translation!, params.book!, params.chapter!);
          const metadata = await bibleContentService.getMetadata(params.translation!);

          // console.log('root component:verses ', verses);
          return buildCurrentReadState(params as unknown as IBibleReadUrlParams, verses, metadata);
        },
      } 
    ]
  },
  // {
  //   path: '*',
  //   element: (<Navigate to="/" replace />)
  // }
])

function App() {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >

      <RouterProvider router={routes} />
    
    </ConfigProvider>
  );
}

export default App;
