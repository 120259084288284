import Button from 'antd/es/button';
import './BibleNavigation.css';
import { ArrowLeftOutlined, ArrowRightOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

export enum BibleNavigationType {
  Left = 1,
  Right = 2,
  Down = 3,
  Up = 4,
}

interface BibleNavigationComponentApi {
  onNavigationRequest: (type: BibleNavigationType) => void;
}

function BibleNavigation({ onNavigationRequest }: BibleNavigationComponentApi) {
  return (
    <div className='bible-navigation'>
      <Button shape="circle" icon={<ArrowLeftOutlined />} size="large" onClick={() => onNavigationRequest(BibleNavigationType.Left)} />
      <Button shape="circle" icon={<DownOutlined />} size="large" onClick={() => onNavigationRequest(BibleNavigationType.Down)} />
      <Button shape="circle" icon={<UpOutlined />} size="large" onClick={() => onNavigationRequest(BibleNavigationType.Up)} />
      <Button shape="circle" icon={<ArrowRightOutlined />} size="large" onClick={() => onNavigationRequest(BibleNavigationType.Right)} />
    </div>
  );
}

export default BibleNavigation;
