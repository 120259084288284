import { IBook } from "../model/book";
import { IChapter } from "../model/chapter";
import { ITranslation } from "../model/translation";

export type FontSize = 'small' | 'middle' | 'big';

export interface IStateId {
  id: string;
}

export interface ISettings {
  availableFontSizes: FontSize[];
  fontSize: FontSize;
}

export interface ICurrentRead extends IStateId {
  translation: ITranslation | null;
  book: IBook | null;
  books: Array<IBook> | null;
  chapter: IChapter | null;
  verses: string[];
  isEmpty: boolean;
}

export interface IBibleReaderState {
  current: ICurrentRead;
  settings: ISettings;
}

export const EMPTY_CURRENT_READ_STATE: ICurrentRead = {
  id: 'EMPTY_CURRENT_READ_STATE',
  translation: null,
  book: null,
  books: null,
  chapter: null,
  verses: [],
  isEmpty: true,
};

const initialState: IBibleReaderState = {
  current: {
    ...EMPTY_CURRENT_READ_STATE
  },
  settings: {
    availableFontSizes: ['small', 'middle', 'big'],
    fontSize: 'small',
  }
};

export default initialState;
