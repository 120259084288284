import './BibleChapter.css';
import '../../shared-styles/font-size.css';

interface BibleChapterContext {
  verses: string[];
  fontSize: 'small' | 'middle' | 'big';
}

function BibleChapter({ verses, fontSize }: BibleChapterContext) {
  const containerClassName = [
    'chapter',
    `${fontSize}-font`
  ].join(' ');

  return (
    <div className={containerClassName}>
      {
        verses.map((verse, index) => (
          <div className='verse' key={index}>
            <span className='no-verse'>{index + 1}</span>
            <span>&nbsp;</span>
            <span className='content-verse'>{verse}</span>
          </div>
        ))
      }
    </div>
  )
}

export default BibleChapter;
