import { generatePath } from "react-router-dom";
import { IBibleReaderState } from "../store/state";

export function buildUrlForPrevBook(state: IBibleReaderState): string {
  let translation = state.current.translation!.code;
  let book = state.current.book!.code;
  let chapter = `${state.current.chapter!.no}`;

  let currentBookIndex = state.current.books!.findIndex((item) => item.code === book);
  let lastBookIndex = state.current.books!.length - 1;

  if ((currentBookIndex - 1) < 0) {
    setLastBook();
  } else {
    setPrevBook();
  }

  return generatePath('/read/bible/:translation/:book/:chapter', { translation, book, chapter });

  function setLastBook() {
    book = state.current.books![lastBookIndex].code;
  }

  function setPrevBook() {
    currentBookIndex -= 1;
    book = state.current.books![currentBookIndex].code;
  }
}

export function buildUrlForNextBook(state: IBibleReaderState): string {
  let translation = state.current.translation!.code;
  let book = state.current.book!.code;
  let chapter = `${state.current.chapter!.no}`;

  let currentBookIndex = state.current.books!.findIndex((item) => item.code === book);
  let lastBookIndex = state.current.books!.length - 1;
  let firstBookIndex = 0;

  if ((currentBookIndex + 1) > lastBookIndex) {
    setFirstBook();
  } else {
    setNextBook();
  }

  return generatePath('/read/bible/:translation/:book/:chapter', { translation, book, chapter });

  function setFirstBook() {
    book = state.current.books![firstBookIndex].code;
  }

  function setNextBook() {
    currentBookIndex += 1;
    book = state.current.books![currentBookIndex].code;
  }
}
