import './BibleTopBar.css';
import { AppstoreOutlined, SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Header } from "antd/es/layout/layout";
import { useContext } from 'react';
import { generatePath, Link, Navigate, Params, useParams } from 'react-router-dom';
import { BibleReaderContext } from '../../store/context';

interface BibleTopBarComponentApi {
  bookName: String;
  chapter: number;
}

function BibleTopBar({ bookName, chapter }: BibleTopBarComponentApi) {
  const bibleReaderContext = useContext(BibleReaderContext);

  const selectBookUrl = generatePath(
    '/read/bible/select-book/from/:translation',
    {
      translation: bibleReaderContext.state.current.translation!.code
    }
  );
  const selectChapterUrl = generatePath(
    '/read/bible/select-chapter/from/:translation/:book',
    {
      translation: bibleReaderContext.state.current.translation!.code,
      book: bibleReaderContext.state.current.book!.code,
    }
  );
  return (
    <Header className='header max-width-for-reading-wrapper'>
      <div className="header-content max-width-for-reading">
        <div className="buttons-left">
          
        </div>
        <div className="buttons-book">
          <Link to={selectBookUrl}>
            <Button type="text">{bookName || ''}</Button>
          </Link>
          <span>&nbsp;</span>
          <Link to={selectChapterUrl}>
          <Button type="text">{chapter || ''}</Button>
          </Link>
        </div>
        <div className="buttons-right">
          <Link to="/read/bible/settings">
            <Button type='text' icon={<SettingOutlined />} size="large"></Button>
          </Link>
          <Link to="/">
            <Button type='text' icon={<AppstoreOutlined />} size="large"></Button>
          </Link>
        </div>
      </div>
    </Header>
  );
}

export default BibleTopBar;
