import { generatePath } from "react-router-dom";
import { IBibleReaderState } from "../store/state";

export function buildUrlForNextChapter(state: IBibleReaderState): string {
  let translation = state.current.translation!.code;
  let book = state.current.book!.code;
  let chapter = `${state.current.chapter!.no}`;

  const currentChapter = state.current.chapter!.no;
  const chaptersInCurrentBook = state.current.book!.numberOfChapters;
  if ((currentChapter + 1) > chaptersInCurrentBook) {
    setNextBook();
  } else {
    setToNextChapter();
  }
  return generatePath('/read/bible/:translation/:book/:chapter', { translation, book, chapter });

  function setToNextChapter() {
    chapter = `${currentChapter + 1}`;
  }

  function setNextBook() {
    let currentBookIndex = state.current.books!.findIndex((item) => item.code === book);
    const noMoreBooks = (currentBookIndex + 1) > state.current.books!.length - 1;
    if (noMoreBooks) {
      currentBookIndex = 0;
    } else {
      currentBookIndex += 1;
    }

    chapter = '1';

    book = state.current.books![currentBookIndex].code;
  }
}

export function buildUrlForPrevChapter(state: IBibleReaderState): string {
  let translation = state.current.translation!.code;
  let book = state.current.book!.code;
  let chapter = `${state.current.chapter!.no}`;

  const currentChapter = state.current.chapter!.no;
  if ((currentChapter - 1) < 1) {
    setPrevBook();
  } else {
    setToPrevChapter();
  }
  return generatePath('/read/bible/:translation/:book/:chapter', { translation, book, chapter });

  function setToPrevChapter() {
    chapter = `${currentChapter - 1}`;
  }

  function setPrevBook() {
    let currentBookIndex = state.current.books!.findIndex((item) => item.code === book);
    const noMoreBooks = (currentBookIndex - 1) < 0;
    if (noMoreBooks) {
      currentBookIndex = state.current.books!.length - 1;
    } else {
      currentBookIndex -= 1;
    }

    chapter = `${state.current.books![currentBookIndex].numberOfChapters}`;

    book = state.current.books![currentBookIndex].code;
  }
}
